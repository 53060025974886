<template>
    <sch-center-student-exam-scroll-wrapper></sch-center-student-exam-scroll-wrapper>
</template>
<script>
import SchCenterStudentExamScrollWrapper from '@/components/scrollWrapper/SchCenterStudentExamScrollWrapper.vue';
export default {
    name: "SchCenterStudentExam",
    components: {
        SchCenterStudentExamScrollWrapper,
    }
}
</script>